import type { FC, ReactNode } from 'react'

type MainLayoutProps = {
  header: ReactNode
  children: ReactNode
  footer: ReactNode
}

export const MainLayout: FC<MainLayoutProps> = ({
  header,
  children,
  footer,
}) => (
  <div className="w-full min-h-screen	flex justify-center bg-gray-200">
    <div className="w-full md:w-3/4 xl:max-w-screen-lg bg-white flex flex-col bg-gray-50">
      {header}
      <main className="p-2 grow">
        {children}
      </main >
      {footer}
    </div>
  </div>
)
