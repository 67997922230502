import { useContext, useState } from 'react'
import type { FC } from 'react'
import { Form, Link, useNavigate } from '@remix-run/react'
import Logo from '~/assets/images/svg/logo_2.svg'
import path from '~/constants/path'
import { RootContext } from '~/root'
import { HeaderSelectBox, HeaderSelectOptions } from '~/components/inputs'
import {
  BicycleGenreModel,
  PartGenreModel,
  SupplyGenreModel,
  PrefectureCodeModel,
} from '~/models'
import { SelectLinkButton } from '~/components/buttons'

export const keys = {
  type: 'user-header-type-key',
  search: 'user-header-search-key',
  height: 'user-header-height-key',
  maker: 'user-hedaer-maker-key',
  prefecture: 'user-hedaer-prefecture-key',
  genre: 'user-hedaer-genre-key',
  minPrice: 'user-hedaer-min-price-key',
  maxPrice: 'user-hedaer-max-price-key',
}

type TopPageSearchFormProps = {
  className: string
  isAuthenticated: boolean
}

const priceRangeList = {
  0: { option: '~10万円' },
  1: { option: '10万円~20万円' },
  2: { option: '20万円~30万円' },
  3: { option: '30万円~40万円' },
  4: { option: '40万円~50万円' },
  5: { option: '50万円~60万円' },
  6: { option: '60万円~70万円' },
  7: { option: '70万円~80万円' },
  8: { option: '80万円~90万円' },
  9: { option: '90万円~100万円' },
  10: { option: '100万円~' },
}

const typeList = {
  0: {
    option: '自転車',
  },
  1: {
    key: '1',
    option: 'パーツ',
  },
  2: {
    option: '用品',
  }
}

const getGenreOptions = (type: string | undefined) => {
  if (!type) return {}
  switch (type) {
    case '0':
      return BicycleGenreModel.list().reduce<HeaderSelectOptions>((pre, [key, val]) => ({
        ...pre,
        [key]: { option: val },
      }), {})
    case '1':
      return PartGenreModel.list().reduce<HeaderSelectOptions>((pre, [key, val]) => ({
        ...pre,
        [key]: { option: val },
      }), {})
    case '2':
      return SupplyGenreModel.list().reduce<HeaderSelectOptions>((pre, [key, val]) => ({
        ...pre,
        [key]: { option: val },
      }), {})
  }
  throw new Error(`unknown type: ${type}`)
}

// Create [minPrice, maxPrice] from priceRangeList
const getRange = (key: number) => {
  if (key === 0) return [undefined, 100_000]
  if (key === 10) return [1_000_000, undefined]
  return [key * 100_000, (key + 1) * 100_000]
}

export const TopPageSearchForm: FC<TopPageSearchFormProps> = ({
  className = '',
  isAuthenticated,
}) => {
  const { makers } = useContext(RootContext)
  const [keywords, setKeywords] = useState<string>('')
  const [type, setType] = useState<string | undefined>()
  const navigate = useNavigate()
  const createBaseURL = (): string => {
    const keywordPath = keywords === '' ? '' : keywords
    return `${path.user.home}search/${keywordPath}?${keys.type}=${type ?? ''}`
  }

  return (
    <header className={`flex flex-col`}>
      <div className={`flex flex-col shadow bg-white md:flex-row w-full items-center md:justify-between p-2 h-auto ${className}`}>
        <Link to="/" className="h-full w-5/12 md:basis-1/3 flex justify-center align-center">
          <img className="m-auto h-7 md:h-auto" src={Logo} />
        </Link>
        <div className="flex justify-end gap-2">
          <div className="">
            <SelectLinkButton to={path.user.cart.home} className="text-center w-fit text-xs">カートを見る</SelectLinkButton>
          </div>
          {isAuthenticated ? (
            <div className="">
              <SelectLinkButton to={path.user.mypage.invoices.home} className="text-center w-fit text-xs">マイページ</SelectLinkButton>
            </div>
          ) : (
            <div className="">
              <SelectLinkButton to={path.user.auth.login} className="text-center w-fit text-xs">ログイン</SelectLinkButton>
            </div>
          )}
        </div>
      </div>
      <div
        className="bg-[url('/images/top.png')] md:bg-[url('/images/top.pc.png')] flex p-4 h-48 sm:h-52 md:h-60 lg:h-80 bg-cover bg-no-repeat"
      >
      </div>
      <nav className="bg-secondary flex flex-col justify-center items-center h-[22rem] md:h-[28rem]">
        <ul className="flex flex-col h-fit bg-white rounded w-4/5 -translate-y-8">
          <li className="flex justify-between hover:bg-gray-200 border-b-2 border-secondary">
            <HeaderSelectBox
              className="text-secondary text-lg md:text-xl font-bold"
              isVertical={true}
              arrow={<img src="/images/svg/icon/chevron-right-rounded.svg" />}
              onChange={v => setType(v ?? '0')}
              label="タイプ"
              options={typeList}
            />
          </li>
          <li className="flex justify-between hover:bg-gray-200 border-b-2 border-secondary">
            <HeaderSelectBox
              className="text-secondary text-lg md:text-xl font-bold"
              isVertical={true}
              arrow={<img src="/images/svg/icon/chevron-right-rounded.svg" />}
              onChange={v => {
                v && navigate(`${createBaseURL()}&${keys.prefecture}=${v}`, { replace: true })
              }}
              label="地域"
              options={
                PrefectureCodeModel.list().reduce<HeaderSelectOptions>((pre, [key, val]) => ({
                  ...pre,
                  [key]: { option: val },
                }), {})}
            />
          </li>
          <li className="flex justify-between hover:bg-gray-200 border-b-2 border-secondary">
            <HeaderSelectBox
              className="text-secondary text-lg md:text-xl font-bold"
              isVertical={true}
              arrow={<img src="/images/svg/icon/chevron-right-rounded.svg" />}
              onChange={v => {
                v && navigate(`${createBaseURL()}&${keys.maker}=${v}`, { replace: true })
              }}
              label="メーカー"
              options={makers.sort((a, b) => a.name < b.name ? -1 : 1)
                .reduce<HeaderSelectOptions>((pre, maker) =>
                  ({ ...pre, [maker.displayID]: { option: maker.name } }), {})
              }
            />
          </li>
          <li className="flex justify-between hover:bg-gray-200 border-b-2 border-secondary">
            <HeaderSelectBox
              className="text-secondary text-lg md:text-xl font-bold"
              isVertical={true}
              arrow={<img src="/images/svg/icon/chevron-right-rounded.svg" />}
              onChange={v => {
                v && navigate(`${createBaseURL()}&${keys.height}=${v}`, { replace: true })
              }}
              label="身長"
              suffix="cm"
              options={[...Array(75).keys()].reduce<HeaderSelectOptions>(((pre, key) => ({
                ...pre,
                [`${(135 + key)}`]: {
                  option: `${(135 + key)}`,
                }
              })), {})}
              disabled={type !== '0'}
            />
          </li>
          <li className="flex justify-between hover:bg-gray-200 border-b-2 border-secondary">
            <HeaderSelectBox
              className="text-secondary text-lg md:text-xl font-bold"
              isVertical={true}
              arrow={<img src="/images/svg/icon/chevron-right-rounded.svg" />}
              onChange={v => {
                v && navigate(`${createBaseURL()}&${keys.genre}=${v}`, { replace: true })
              }}
              label="ジャンル"
              options={getGenreOptions(type)}
              disabled={type === undefined}
            />
          </li>
          <li className="flex justify-between hover:bg-gray-200">
            <HeaderSelectBox
              className="text-secondary text-lg md:text-xl font-bold"
              isVertical={true}
              arrow={<img src="/images/svg/icon/chevron-right-rounded.svg" />}
              onChange={v => {
                const [minPrice, maxPrice] = getRange(Number(v))
                v && navigate(
                  `${createBaseURL()}&${keys.minPrice}=${String(minPrice ?? '')}&${keys.maxPrice}=${String(maxPrice ?? '')}`,
                  { replace: true },
                )
              }}
              label="価格"
              options={priceRangeList}
            />
          </li>
        </ul>
        <Form
          className="w-4/5"
          method="GET"
          onSubmit={e => {
            e.preventDefault()
            return navigate(`${createBaseURL()}`)
          }}
        >
          <div className="h-full flex contents-center">
            <input
              id="search"
              name="search"
              type="text"
              value={keywords}
              onChange={event => setKeywords(event.target.value)}
              placeholder="キーワードで検索: 名古屋 giant 2019"
              className="w-full m-auto px-2 py-4 rounded shadow-lg placeholder:gray-400 placeholder:text-sm bg-[url('/images/svg/icon/search.svg')] bg-no-repeat bg-[top_50%_right_2%]" />
          </div>
        </Form>
      </nav>
    </header>
  )
}
