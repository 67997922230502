import { json } from '@remix-run/node'
import type { LoaderFunctionArgs } from '@remix-run/node'
import { Outlet, useLocation, useLoaderData } from '@remix-run/react'
import { createContext, useContext } from 'react'
import { UserSession } from '~/utils/session.server'
import { MainLayout } from '~/components/templates/user/main'
import { Header } from '~/components/compounds/user/header'
import { TopPageSearchForm } from '~/components/compounds/user/top-page-search-form'
import { Footer } from '~/components/footer'
import { prodTestEmails } from '~/constants'

export const loader = async ({ request }: LoaderFunctionArgs) => {
  const session = await UserSession.create(request)
  return json({
    isAuthenticated: session.isAuthenticated,
    isProdTest: session.data ? prodTestEmails.includes(session.data.email) : false,
  })
}

type ContextType = {
  isAuthenticated: boolean
  isProdTest: boolean
}

const Context = createContext<ContextType>({
  isAuthenticated: false,
  isProdTest: false,
})

export const useUserContext = () => useContext(Context)

export default function User() {
  const data = useLoaderData<typeof loader>()
  const location = useLocation();
  if (!data) return null

  return (
    <Context.Provider value={{
      isAuthenticated: data.isAuthenticated,
      isProdTest: data.isProdTest,
    }}>
      <MainLayout
        header={location.pathname === "/" ? (
          <TopPageSearchForm className="bg-white" isAuthenticated={data.isAuthenticated} />
        ) : (
          <Header className="bg-white" isAuthenticated={data.isAuthenticated} />
        )}
        footer={<Footer />}
      >
        <Outlet />
      </MainLayout>
    </Context.Provider>
  )
}
